@import '../CommonVariables.scss';

#alertBox.error {
  color: $alert-30;
  background-color: $color-12;
  font-size: $ft-body__small;
}

#alertBox.failed {
  color: $alert-30;
  background-color: $alert-95;
  font-size: $ft-body__small;
}

#alertBox.success {
  color: $success-30;
  background-color: $success-95;
  font-size: $ft-body__small;
}

#alertBox.info {
  color: $info-30;
  background-color: $info-95;
  font-size: $ft-body__small;
}

#alertBox.warning {
  color: $warning-30;
  background-color: $warning-95;
  font-size: $ft-body__small;
}

#alertBox {
  display: flex;
  padding: 16px 24px 16px 24px;
  gap: 10px;
  align-items: flex-start;
  border-radius: 10px;

  .info-title,
  .success-title,
  .warning-title,
  .error-title,
  .failed-title {
    font-size: $ft-body__medium !important;
  }

  .button-container {
    display: flex;
    align-items: center;
    width: fit-content;

    .button-notification {
      margin-top: 10px;
      width: max-content;
      background-color: transparent !important;
      &.info {
        color: $info-30;
        &:hover {
          color: $info;
        }
      }
      &.success {
        color: $success-30;
        &:hover {
          color: $success;
        }
      }
      &.warning {
        color: $warning-30;
        &:hover {
          color: $warning;
        }
      }
      &.error {
        color: $alert-30;
        &:hover {
          color: $alert;
        }
      }
      &.failed {
        color: $alert-30;
        &:hover {
          color: $alert;
        }
      }
    }
  }
}
