@import 'src/common/CommonVariables';

.custom-chip {
  max-width: fit-content !important;
  border-radius: 16px;
  font-size: $m !important;
  text-overflow: ellipsis;
  overflow: hidden;
  .MuiChip-icon + .MuiChip-label {
    padding-left: 0 !important;
  }
  .custom-icon {
    width: $m !important;
  }
}

.custom-chip.small-chip {
  span {
    padding: 6px 8px;
  }
  border-radius: 16px;
  font-size: $ft-body__xsmall !important;
  word-break: keep-all;
  height: auto;
}

.custom-chip.chip {
  border-radius: 4px;
  > span {
    color: $base-80 !important;
  }
  * svg path {
    stroke: $base-80;
  }
}

.custom-chip.accent {
  background-color: $accent-92 !important;
  > span {
    color: $accent;
  }
  * svg path {
    stroke: $accent !important;
  }
}

.custom-chip.info {
  background-color: $info-95 !important;
  > span {
    color: $info-30;
  }
  * svg path {
    stroke: $info-30 !important;
  }
}

.custom-chip.neutral {
  background-color: $base-20 !important;
  > span {
    color: $base-70;
  }
  * svg path {
    stroke: $base-70 !important;
  }
}

.custom-chip.success {
  background-color: $success-95 !important;
  > span {
    color: $success-30;
  }
  * svg path {
    stroke: $success-30 !important;
  }
}

.custom-chip.error {
  background-color: $alert-95 !important;
  > span {
    color: $alert;
  }
  * svg path {
    stroke: $alert !important;
  }
}

.custom-chip.warning {
  background-color: $warning-95 !important;
  > span {
    color: $warning;
  }
  * svg path {
    stroke: $warning !important;
  }
}

.custom-chip.MuiChip-deletable:not(.chip) .MuiChip-label {
  padding-right: 2px !important;
}

.custom-chip:not(.chip) {
  .custom-icon:not(.MuiChip-deleteIcon) {
    width: $m !important;
    padding-left: $m !important;
  }
  .MuiChip-deleteIcon {
    width: 9px !important;
    padding-right: $m !important;
  }
}
.custom-chip.small-chip:not(.chip) {
  .custom-icon:not(.MuiChip-deleteIcon) {
    width: 11px !important;
    padding-left: 11px !important;
  }
  .MuiChip-deleteIcon {
    width: 8px !important;
    padding-right: 11px !important;
  }
}
