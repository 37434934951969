@import 'src/common/CommonVariables';

#nav-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &__menu {
    display: flex;
    flex-direction: column;
    font-size: $ft-body__small !important;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .nav-bar-directory {
      display: flex;
      flex-direction: column;
      padding: $m;
      p {
        font-size: inherit;
        padding: $m;
        font-weight: 700;
      }
    }
    .nav-bar-sub-directory {
      display: flex;
      flex-direction: column;
      padding-bottom: $m;
      p {
        font-size: inherit;
        padding: $m $l;
        font-weight: 700;
      }
    }
    .middle-nav {
      border-top: 1px solid $base-30;
      border-bottom: 1px solid $base-30;
    }
    .nav-bar__menu-item {
      padding: $s $l;
      display: flex;
      gap: 5px;
      align-items: center;
      cursor: pointer;
      > div {
        height: 20px;
        width: 20px;
      }
    }
    .nav-bar__menu-item:hover {
      background-color: $base-30;
    }
    .navbar-selected-tab {
      color: $accent;
      & svg > * {
        fill: $accent !important;
      }
    }
  }
  &__privacy-link {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    a {
      font-size: $ft-body__medium;
      color: $base-70;
      text-decoration: none;
      letter-spacing: -0.02em;
      user-select: none;
      padding-left: 9px;
    }
    .session-button {
      justify-content: flex-start;
      border: none;
      text-transform: none;
      max-width: none;
    }
    .navbar-account-select {
      border-radius: 8px;
      div {
        .custom-icon {
          padding: 0 !important;
          padding-right: 8px !important;
        }
        font-weight: 700;
      }
      .navbar-render-account-select {
        display: flex;
        align-items: end;
      }
      .MuiSelect-select {
        padding: 12px 20px;
        padding-right: 46px !important;
      }
      .MuiSelect-icon {
        padding-right: 6px;
      }
    }
  }
  img {
    width: 80%;
  }

  .navbar-workspace-select {
    align-self: center;
    width: 95%;
    max-width: 132px;
    border-radius: 8px;
    font-size: $ft-body__small;
    > div {
      padding: 12px;
      padding-right: 32px;
    }
  }

  .MuiSelect-select svg {
    display: inherit !important;
  }
}
