@import 'src/common/CommonVariables';

@font-face {
  font-family: ft-system-bold;
  src: url(./assets/fonts/ft-system/bold.otf);
}

@font-face {
  font-family: ft-system-regular;
  src: url(./assets/fonts/ft-system/regular.otf);
}

// This style rule removes the check mark
// from the multiselect component globally
.MuiSelect-select svg {
  display: none !important;
}

.App {
  background: $base-10;
}

#app-container {
  display: grid;
  grid-template-columns: 150px calc(100% - 150px);
  height: 100vh;
  #home-container {
    width: 100%;
    position: relative;
    &::before,
    &::after {
      content: '';
      width: 300px;
      height: 100vh;
      z-index: -1;
      position: absolute;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    &::before {
      left: 0;
      background-image: url('../src/assets/imgs/background-sticks-left.svg');
    }

    &::after {
      right: 0;
      background-image: url('../src/assets/imgs/background-sticks-right.svg');
    }

    &__myspace {
      position: fixed;
      width: calc(100% - 150px); // 150px is the width of the sidebar
      height: 100%;
      z-index: 1;
      background-color: white;
    }

    #workspace {
      background-color: $base-0;
      grid-template-rows: [header] auto [body] 1fr [footer] 0 !important;
      .render-view__body {
        height: calc(100% - 50px);
        p {
          font-size: 12px !important;
          color: $base-80;
        }
      }
    }
  }
}

// Platform scrolls
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar {
  width: 0.4em !important;
}

::-webkit-scrollbar-track {
  background-color: $base-30 !important;
  border-radius: 1rem !important;
}

::-webkit-scrollbar-thumb {
  background-color: $base-40 !important;
  border-radius: 1rem !important;
}
