@import 'src/common/CommonVariables';

@mixin left-border($color) {
  border-left: 2px solid $color !important;
}

.table-label {
  font-size: $ft-body__medium;
}

.wrapp-preview-table {
  overflow-x: auto;
  width: -webkit-fill-available;
  width: -moz-available;
}

.preview-table {
  width: 100%;
  font-size: $ft-body__small;

  thead {
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .preview-hover:hover {
    background-color: $base-20;
  }

  .select {
    color: white;
    background-color: $base-80 !important;

    td {
      height: auto;
    }

    td .first-column {
      text-wrap: wrap;
    }
  }

  tr {
    display: table-row;
    width: inherit;

    th,
    td {
      display: table-cell;
    }
  }

  tbody {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .preview-context {
    font-size: $ft-body__small !important;
    color: inherit;
    text-align: inherit;
    word-break: keep-all;
  }

  .limitedVisibility,
  .unLimitedVisibility {
    display: flex;
    align-items: center !important;
    font-family: ft-system-regular;
    .chip-style {
      padding: 6px 12px 6px 12px;
      max-width: 250px !important;
    }
  }
  .limitedVisibility {
    div.custom-chip:has(+ .preview-context) {
      width: 100%;
    }
  }
}

.preview-table-row {
  .tooltipBox {
    display: flex;
    justify-content: flex-end;
  }

  .context-warning {
    border-right: 2px solid $warning;
  }
  .context-error {
    border-right: 2px solid $alert;
  }
  .context-green {
    border-right: 2px solid $success;
  }

  td {
    > div {
      align-self: center;
    }
    .first-column {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      display: inline-block;
      line-height: 32px;
    }
  }

  .preview-flex {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
  }

  td:first-child,
  th:first-child {
    width: 150px;
    min-width: 150px;
    text-align: initial !important;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 150px;
    min-width: 150px;
    justify-content: right !important;
    padding-right: 10px;

    .preview-flex {
      justify-content: flex-end;
    }
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: -webkit-fill-available;
    width: -moz-available;
    text-align: left !important;
    padding-left: 10px;

    .preview-flex {
      width: 100%;
      justify-content: flex-start;
      align-items: baseline;
      align-content: center;
    }
  }
  .context-checkbox {
    > div {
      margin-top: 6px !important;
      cursor: pointer;
    }
  }
  .context-input-header,
  .context-td:last-child {
    width: 100%;
    min-width: 500px;
    .context-input {
      font-family: ft-system-regular;
      padding: 15px;
      border-radius: 8px;
      width: 100%;
      align-self: center;
    }
    .context-input:focus-within {
      outline: none !important;
      border-color: $accent !important;
      box-shadow: $focusBox-shadow-color !important;
    }
  }
}

.preview-table-row:last-child {
  td {
    min-height: 24px;
    padding: 13px;
    border-bottom: none;
  }
  .context-td {
    height: inherit;
  }
}

.tooltip {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    line-height: 18px;
  }
}

.previewCategoricalOpen {
  height: 100%;
  flex-wrap: wrap;
}

.disregard {
  @include left-border($color-7);
  background-color: $base-20;
  opacity: 0.5;
}

.preview-edited {
  @include left-border($accent);
}

.preview-added {
  @include left-border($color-5);
}
